import React, { useRef } from 'react'
import CustomerAcquisition from '../components/CustomerAcquisition'
import GraphDetailedInfo from '../components/GraphDetailedInfo'
import '../styles/piechart.css'
import PiechartDesign from '../components/PiechartDesign'
import CustomerAcquisitionBanner from '../components/CustomerAcquisitionBanner'
import whiteEquals from '../assets/images/white-equals.svg'
import backgroundImage from '../assets/images/design-banner.png'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react';

function Design({menuHeight}) {

    const equalsSvg = useRef(null)
    const path1 = useRef(null)
    const path2 = useRef(null)
    const path3 = useRef(null)

    const windiwWidth = window.innerWidth


    const Design = {
        heading: "Design",
        subHeading: "Web Design + Logo Design = Brand Kit",
        para: `
          In today’s dynamic digital landscape, consumers are constantly interacting with a wide array of content—whether it’s text, visuals, or videos. Effective design is crucial in capturing attention and sparking meaningful engagement with your brand. Studies consistently demonstrate that even the most exceptional products or services can falter if they lack a strong, cohesive design presence that resonates with their audience.
        `,
        paras: `
            We understand that exceptional design is the cornerstone of any successful brand and marketing strategy. Our design services blend creativity, strategic insight, and cutting-edge technology to deliver visually captivating, user-focused experiences that drive real results.
        `
    }  


    // Accordian Data Passing

    const EPR = {
        heading : "Our Design Expertise",
        para : ``,

        accordianList : [
            {
                id : 1,
                heading : "Brand Identity & Logo Design",
                para : `
                    Your brand’s identity is its first impression. Our design team works closely with you to craft logos and brand identities that are timeless, memorable, and reflective of your core values.
                `
            },
            {
                id : 2,
                heading : "Website Design & Development",
                para : `
                    We build stunning, responsive websites that are optimised for both user experience and performance. From layout to colour schemes, we ensure every design element is intentional and aligned with your business goals.    
                `
            },
            {
                id : 3,
                heading : "UI/UX Design",
                para : `
                    Our user interface (UI) and user experience (UX) designs focus on creating seamless, intuitive, and engaging digital experiences that keep your audience coming back.
                `
            },
            {
                id : 4,
                heading : "Print & Design Media Design",
                para : `
                Whether it’s brochures, social media graphics, or digital ads, our creative team produces high-quality visuals that capture attention and effectively communicate your message.                
                `
            },
            {
                id : 5,
                heading : "Packaging Design",
                para : `
                    We design product packaging that not only looks great on the shelf but also communicates your brand’s story and values, enhancing the consumer experience.
                `
            },
            
        ]
    }

    const CRM = {
        heading : "Why Choose Us?",
        para : ``,

        accordianList : [
            {
                id : 1,
                heading : "Creative Innovation",
                para : `
                    Our team stays ahead of design trends to offer fresh, innovative concepts that set your brand apart.    
                `
            },
            {
                id : 2,
                heading : "Tailored Solutions",
                para : `
                    No two businesses are the same. We deliver designs that are tailored to your specific needs, ensuring a unique brand presence.
                `
            },
            {
                id : 3,
                heading : "Collaborative Partnership",
                para : `
                    We believe in working closely with our clients throughout the design process, fostering open communication and alignment at every step.
                `
            },
            {
                id : 4,
                heading : "End-to-End Support",
                para : `
                    From initial concept to final execution, we offer comprehensive design solutions that ensure a seamless experience.
                `
            },
            
        ]
    }

    const paymentGateway = {
        heading : "Deep Stick Market Research",
        para : `Uncover Insights: What Happens In Conference Room, Stays In Conference Room

In the ever-evolving world of business, making informed decisions based on data and profound market insights is essential for success. "Deep Stick Market Research Service by DUMOSH" specializes in diving beneath the surface to provide you with comprehensive market research, empowering you with the in-depth knowledge needed for strategic decision-making. Let's explore how our market research service, brought to you by DUMOSH, can drive your business forward.`,

        accordianList : [
            {
                id : 1,
                heading : "Understanding Deep Stick Market Research ",
                para : `
                    Deep Stick Market Research is a specialized and meticulous approach to market research. It involves a thorough analysis and examination of market dynamics, consumer behaviors, and industry trends. We delve deep to uncover hidden gems of knowledge that might not be immediately apparent. Our aim is to empower your business with actionable insights.
                `
            },
            {
                id : 2,
                heading : "Our Comprehensive Deep Stick Market Research Service",
                para : `
                   At "Deep Stick Market Research by DUMOSH," we are dedicated to delivering insights that drive your business forward. Our service encompasses a wide array of research methodologies and approaches to ensure you have a thorough understanding of your market:
<br/><br/>
<b>Market Analysis :</b> We conduct a comprehensive analysis of your market, studying current trends, competitive landscapes, and potential growth opportunities.
<br/><br/>
<b>Consumer Behavior Research :</b> Understanding your target audience is pivotal. We delve into consumer behavior, preferences, and purchase patterns to help you tailor your strategies effectively.
<br/><br/>
<b>Competitive Intelligence :</b> We provide you with valuable insights into your competitors' strategies, strengths, weaknesses, and areas where you can gain a competitive edge.
<br/><br/>
<b>Custom Research Solutions :</b> We offer tailor-made research solutions designed to address your unique business challenges and provide answers to your specific questions.
<br/><br/>
<b>Data-Driven Insights :</b> Our research is grounded in data and analytics, ensuring your decisions are based on well-founded information.
                `
            },
            {
                id : 3,
                heading : "Unlocking The Value Of Deep Stick Market Research ",
                para : `
                    <b>Informed Decision-Making :</b> Deep Stick market research equips you to make well-informed decisions, minimizing uncertainty and risk.
<br/><br/>
<b>Competitive Advantage :</b> Profound insights give you a competitive edge, helping you stay ahead in a rapidly evolving market.
<br/><br/>
<b>Identifying Opportunities :</b> Unearth hidden growth opportunities, product development potential, or new market avenues.
<br/><br/>
<b>Enhanced Customer Understanding :</b>Gain deeper insights into your customers, their needs, and how to serve those needs effectively.
                `
            },
            {
                id : 4,
                heading : "Our Dedication To Excellence  ",
                para : `
                    At "Deep Stick Market Research by DUMOSH," we are unwavering in our commitment to delivering excellence in market research. We acknowledge that every business is unique, and our research strategies are customized to align precisely with your distinct goals and objectives.
                `
            },
            {
                id : 5,
                heading : "Ready To Unearth Strategic Insights With PRODMAX",
                para : `
                    Are you prepared to explore the depths of your market and drive your business forward through well-informed, data-driven decisions? Contact us today to discover how "Deep Stick Market Research by DUMOSH" can help you unearth critical insights, identify opportunities, and achieve your business objectives. Your success is our mission, and we're excited to navigate the world of deep market research with you, brought to you by DUMOSH.
                `
            },
            
        ]
    }

    const ecommerce = {
        heading : "Media & Campaign Planning",
        para : `Elevate Your Brand: Media & Campaign Planning Service

In the realm of branding solutions, a well-crafted media and campaign strategy can be a game-changer. At DUMOSH, our "Media & Campaign Planning Service" is the driving force behind your brand's success. We offer comprehensive solutions that guide you through the intricacies of media planning and campaign management, ensuring that your brand reaches its audience effectively and leaves a lasting impression.
`,

        accordianList : [
            {
                id : 1,
                heading : "What Is Media & Campaign Planning In Branding? ",
                para : `
                    Media & Campaign Planning in Branding is the strategic process of determining how, when, and where to deliver your brand's messages to your target audience. It involves meticulously planning and managing various media channels and campaigns to create a cohesive and compelling brand narrative.`
            },
            {
                id : 2,
                heading : "Our Media & Campaign Planning Service",
                para : `
                    At DUMOSH, we understand that successful branding requires a well-structured plan. Our comprehensive service is designed to help you create and execute impactful media and campaign strategies that resonate with your brand's identity:
<br/><br/>
<b>Audience Profiling :</b> We delve into understanding your target audience, their preferences, behaviors, and needs. This information shapes the foundation of your campaigns, ensuring they connect effectively with your ideal customers.
<br/><br/>
<b>Media Selection :</b> We identify the most suitable media channels for your brand, whether it's digital, print, social, or traditional advertising. Our goal is to maximize your brand's reach and engagement.
<br/><br/>
<b>Campaign Strategy :</b> We develop a detailed campaign strategy that outlines your brand's objectives, messaging, budget, and timeline. This strategy is the roadmap to building a compelling brand narrative.
<br/><br/>
<b>Content Creation :</b> Our team of content experts crafts compelling and relevant content that aligns with your brand's values and resonates with your target audience. From ad copy to multimedia content, we ensure your brand's message is conveyed effectively.
<br/><br/>
<b>Channel Management :</b> We take charge of the logistics of campaign implementation, managing ad placements, social media posts, email marketing, and other brand communication.
<br/><br/>
<b>Analytics and Optimization :</b> We continually monitor the performance of your campaigns, making data-driven adjustments and optimizations to ensure your brand's message is reaching its full potential.
                `
            },
            {
                id : 3,
                heading : "The Value Of Media & Campaign Planning In Branding",
                para : `
                    Whether you're a startup looking to make a mark or an established business seeking to refresh your brand, our branding solutions are tailored to your unique needs. Our team of experts understands the intricacies of branding across various industries, ensuring that your brand strategy aligns with your specific goals and objectives.
                `
            },
            {
                id : 4,
                heading : "Our Commitment To Your Brand's Success ",
                para : `
                    At DUMOSH, we are dedicated to delivering brand success through our Media & Campaign Planning Service. We understand that every brand is unique, and our planning strategies are custom-tailored to align with your brand's specific goals and objectives.
                `
            },
            {
                id : 5,
                heading : "Ready To Elevate Your Brand",
                para : `
                    Are you prepared to elevate your brand through a well-structured media and campaign strategy? Contact us today to explore how our experts can help you create and execute effective media and campaign plans, maximize your brand's reach, and achieve your branding objectives. Your brand's success is our mission, and we're eager to guide you through the world of strategic planning.
                `
            },
            
            
        ]
    }



    const appDevelopment = {
        heading : "Print,OOH,Mainline Media",
        para : `Let’s Paint The Town Red: Print, Out-of-Home (OOH), and Mainline Media Services

In the ever-evolving landscape of advertising, the power of traditional media should not be underestimated. At DUMOSH, our "Print, Out-of-Home (OOH), and Mainline Media Services" are designed to amplify your brand's visibility and reach across conventional platforms. Let's explore how our expertise in these areas can complement your comprehensive marketing strategy.
`,

        accordianList : [
            {
                id : 1,
                heading : "The Significant Of Traditional Media",
                para : `
                    In the digital age, traditional media still holds a vital place in the marketing mix. Print, Out-of-Home (OOH), and Mainline Media provide unique advantages:
<br/><br/>
<b>Print Media :</b> Magazines, newspapers, brochures, and direct mail offer tangible, credible, and targeted ways to reach your audience. They provide a lasting impression and can be a valuable addition to your brand's storytelling.
<br/><br/>
<b>Out-of-Home (OOH) Media :</b> Billboards, transit ads, and other OOH formats ensure your brand is visible to a vast, often captive, audience. They are particularly effective in reaching local and mobile consumers.
<br/><br/>
<b>Mainline Media :</b> Television, radio, and cinema advertising offer extensive reach and the ability to tap into the broadest and diverse audience segments. They provide an opportunity for engaging, audio-visual storytelling.
                `
            },
            {
                id : 2,
                heading : "Our Print,Out-of-Home,and Mainline Media Services",
                para : `
                   At DUMOSH, we understand that a holistic marketing strategy should include a blend of traditional and digital media. Our services encompass each of these key traditional media categories:
<br/><br/>
<b>Print Media Services :</b>
<br/><br/>
<b>Magazine and Newspaper Advertising :</b> We help you select and craft advertisements for magazines and newspapers that align with your target audience.
<br/><br/>
<b>Direct Mail Campaigns :</b> We design and execute direct mail campaigns, ensuring your brand's message reaches the right mailboxes.
<br/><br/>
<b>Brochure Design :</b> Our creative team develops impactful brochures that showcase your brand's products or services.
<br/><br/>
<b>Out-of-Home (OOH) Media Services :</b>
<br/><br/>
<b>Billboard Advertising :</b> We manage billboard campaigns, selecting prime locations to maximize visibility.
<br/><br/>
<b>Transit Advertising :</b> We ensure your brand is seen by commuters, pedestrians, and drivers through transit ads on buses, trams, and other vehicles.
<br/><br/>
<b>Outdoor Events :</b> We help you leverage outdoor events, sponsorships, and activations to enhance brand visibility.
<br/><br/>
<b>Mainline Media Services :</b>
<br/><br/>
<b>Television Advertising :</b> We create and manage television ad campaigns, reaching viewers through both local and national broadcasting.
<br/><br/>
<b>Radio Advertising :</b> We develop and run radio ad campaigns, ensuring your brand's message is heard by a wide-ranging audience.
<br/><br/>
<b>Cinema Advertising :</b> We facilitate cinema advertising, targeting audiences in theaters with engaging audio-visual content.
<br/><br/>
<b>Why Traditional Media Matters</b>
<br/><br/>
<b>Broad Audience Reach :</b> Traditional media allows you to reach a wide and diverse audience, tapping into demographics that may be less active online.
<br/><br/>
<b>Tangible Impact :</b> Print media provides a physical, tangible impression, while OOH and Mainline Media offer immersive, audio-visual experiences.
<br/><br/>
<b>Complementing Digital :</b> Traditional media complements your digital efforts, creating a comprehensive marketing strategy.
<br/><br/>
<b>Credibility and Trust :</b> Traditional media often carries a level of credibility and trust, enhancing your brand's reputation.
                `
            },
            {
                id : 3,
                heading : "Our Commitment To Your Brand's Visibility",
                para : `
                    At DUMOSH, we are dedicated to enhancing your brand's visibility and reach through our Print, Out-of-Home (OOH), and Mainline Media Services. We understand the significance of a well-balanced marketing approach, and we tailor our services to meet your unique goals and target audience.
                `
            },
            {
                id : 4,
                heading : "Ready To Enhance Your Brand's Visibility?",
                para : `
                    Are you ready to enhance your brand's visibility across traditional media platforms? Contact us today to explore how our experts can help you create and execute effective print, OOH, and mainline media campaigns, expanding your brand's reach and achieving your marketing objectives. Your brand's visibility and success are our mission, and we're excited to elevate your traditional media presence.
                `
            },
            
            
        ]
    }

    const UIUX = {
        heading : "Film Production, AVs & Product Photography",
        para : `Visual Excellence: Because Beautiful Sells. Film Production, Audio-Visuals (AVs), and Product Photography Services

In the world of visual storytelling, nothing is more compelling than well-crafted visuals. At DUMOSH, our "Film Production, Audio-Visuals (AVs), and Product Photography Services" are designed to bring your brand's vision to life. We specialize in creating captivating videos, audio-visual content, and product photography that showcase your brand at its best. Let's explore how our expertise in these visual mediums can elevate your brand's image.
`,

        accordianList : [
            {
                id : 1,
                heading : "The Empact Of Visual Storytelling",
                para : `
                    Visual storytelling is a dynamic way to engage your audience and convey your brand's message effectively. It encompasses various mediums, each with its unique strengths:
<br/><br/>
<b>Film Production :</b> Creating engaging films and videos allows you to tell your brand's story, connect with your audience emotionally, and deliver a memorable visual experience.
<br/><br/>
<b>Audio-Visuals (AVs) :</b> AVs are powerful tools for training, presentations, and conveying complex information in a clear and engaging manner.
<br/><br/>
<b>Product Photography :</b> High-quality product photography is essential for e-commerce, advertising, and brand promotion, allowing your products to shine and speak for themselves.
<br/><br/>
Our Film Production, Audio-Visuals (AVs), and Product Photography Services
<br/><br/>
At DUMOSH, we understand the impact of compelling visuals on brand identity. Our services encompass a wide array of visual mediums:
                `
            },
            {
                id : 2,
                heading : "Film Production Services",
                para : `
                   At DUMOSH, we understand that successful branding requires a well-structured plan. Our comprehensive service is designed to help you create and execute impactful media and campaign strategies that resonate with your brand's identity:
<br/><br/>
<b>Scripting and Storyboarding :</b> We collaborate with you to develop captivating narratives and storyboards for your films.
<br/><br/>
<b>Cinematography :</b> Our team of skilled cinematographers brings your vision to life through top-tier filming techniques.
<br/><br/>
<b>Editing and Post-Production :</b> We ensure your films are polished to perfection, with expert editing and post-production services. `
            },
            {
                id : 3,
                heading : "Audio-Visual (AV) Services",
                para : `
                    <b>Content Development :</b> We create clear, concise, and engaging AV content for your specific needs, whether it's training materials or informative presentations.
<br/><br/>
<b>Scripting and Voiceovers :</b> Our scripting and voiceover services ensure that your AVs convey information effectively and resonate with your audience.
                `
            },
            {
                id : 4,
                heading : "Product Photography Services",
                para : `
                    <b>Studio and On-Location Photography :</b> Our product photographers capture your products in the best possible light, whether in a studio setting or on location.
<br/><br/>
<b>E-commerce and Advertising Photography :</b> We specialize in product photography for e-commerce, advertising, and brand promotion, ensuring your products look their best.
                `
            },
            {
                id : 5,
                heading : "Why Visual Excellence Matters",
                para : `
                    <b>Brand Identity :</b> Compelling visuals strengthen your brand identity and set you apart from the competition.
<br/><br/>
<b>Engagement :</b> Well-crafted films and AVs captivate your audience and make complex information more accessible.
<br/><br/>
<b>Sales and Conversion :</b> High-quality product photography is essential for driving sales and conversions, both in e-commerce and traditional retail.
<br/><br/>
<b>Memorability :</b> Visually striking content is more memorable, leaving a lasting impression on your audience.
                `
            },
            {
                id : 6,
                heading : "Our Commitment To Visual Excellence",
                para : `
                   At DUMOSH, we are committed to delivering visual excellence through our Film Production, Audio-Visuals (AVs), and Product Photography Services. We recognize that each brand is unique, and our services are custom-tailored to align with your specific visual goals and objectives.
                `
            },
            {
                id : 7,
                heading : "Ready To Achieve Visual Excellence",
                para : `
                    Are you ready to elevate your brand's image through compelling films, AVs, and product photography? Contact us today to explore how our experts can help you create and execute captivating visual content, enhancing your brand's identity and achieving your marketing objectives. Visual excellence and your brand's success are our mission, and we're excited to bring your vision to life..
                `
            },
            
            
        ]
    }

    const automation = {
        heading : "Copywriting & Original Content Creation",
        para : `Your Story, Perfected: Copywriting & Original Content Creation Services

In the realm of digital marketing, words are the bridge between your brand and your audience. At DUMOSH, our "Copywriting & Original Content Creation Services" are dedicated to crafting compelling narratives and creating engaging content that captivates your target audience. Let's explore how our expertise in copywriting and content creation can elevate your brand's communication.
`,

        accordianList : [
            {
                id : 1,
                heading : "The Power Of Words In Branding",
                para : `
                   Words are the building blocks of your brand's voice and message. Effective copywriting and original content creation play a pivotal role in:
<br/><br/>
Copywriting Services:
<br/><br/>
<b>Compelling Copy :</b> Our copywriters craft persuasive, concise, and impactful copy for various marketing materials, including websites, brochures, ads, and more.
<br/><br/>
<b>SEO-Optimized Content :</b> We create content that not only engages readers but is also optimized for search engines, improving your brand's online visibility.
<br/><br/>
<b>Brand Messaging :</b> We develop and refine your brand's messaging to ensure consistency and resonance with your target audience.
                `
            },
            {
                id : 2,
                heading : "Original Content Creation Services",
                para : `
                    <b>Blogs and Articles :</b> Our content creators produce informative and engaging blog posts and articles that showcase your brand's expertise and provide value to your audience.
<br/><br/>
<b>Social Media Content :</b> We develop social media content that sparks conversations, engages your followers, and fosters community building.
                `
            },
            {
                id : 3,
                heading : "Why Copywriting And Original Conent Creation Matter",
                para : `
                    <b>Brand Consistency :</b> Consistent and effective messaging reinforces your brand's identity and value.
<br/><br/>
<b>Audience Engagement :</b> Compelling content captivates your audience, keeping them engaged and eager to interact with your brand.
<br/><br/>
<b>SEO Benefits :</b> SEO-optimized content improves your online visibility and search engine rankings, driving organic traffic to your website.
<br/><br/>
<b>Lead Generation :</b> Valuable content like e-books and guides can be powerful tools for lead generation and nurturing.
                `
            },
            {
                id : 4,
                heading : "Our Commitment To Your Brand's Voice",
                para : `
                    At DUMOSH, we are committed to shaping your brand's message through expert copywriting and original content creation. We understand that each brand has its unique story and voice, and our services are tailored to align with your specific communication goals and objectives.
                `
            },
            {
                id : 5,
                heading : "Ready To Craft Words That Resonate?",
                para : `
                    Are you ready to elevate your brand's communication with persuasive copy and engaging content? Contact us today to explore how our experts can help you create and deliver content that resonates with your audience, strengthens your brand's identity, and achieves your marketing objectives. Your brand's voice and success are our mission, and we're excited to craft words that captivate your audience.
                `
            },
            
            
        ]
    }

    const security = {
        heading : "Creatives & Co-laterals",
        para : `Elevate Your Brand: Here Creativity Meets Functionality. Creatives & Collaterals Services

In the world of branding, every detail counts. At DUMOSH, our "Creatives & Collaterals Services" are designed to provide your brand with the visual and tangible elements that leave a lasting impression. We specialize in crafting creative assets and collateral materials that elevate your brand identity and create a cohesive brand presence. Let's explore how our expertise in creatives and collaterals can transform your brand.
`,

        accordianList : [
            {
                id : 1,
                heading : "The Essence Of Creatives & Collaterals In Branding",
                para : `
                    Creatives and collaterals encompass a wide array of visual and tangible elements that communicate your brand's identity. These include:
<br/><br/>
<b>Logo Design :</b> Your logo is the face of your brand. A well-designed logo sets the tone for your entire brand identity.
<br/><br/>
<b>Brand Colors :</b> Consistent color schemes are crucial for brand recognition and resonance.
<br/><br/>
<b>Typography :</b> Choosing the right fonts for your brand ensures a consistent and recognizable visual identity.
<br/><br/>
<b>Print Collaterals :</b> Brochures, business cards, and marketing materials create tangible connections with your audience.
<br/><br/>
<b>Digital Creatives :</b> Eye-catching digital assets, such as banners, graphics, and infographics, enhance your online presence.
                `
            },
            {
                id : 2,
                heading : "Our Creatives & Collaterals Services",
                para : `
                   At DUMOSH, we understand that the visual and tangible elements of your brand are essential to its success. Our services encompass a wide array of creatives and collateral solutions:
<br/><br/>
<b>Visual Brand Identity :</b>
<br/><br/>
<b>Logo Design :</b> We create or refresh your logo to reflect your brand's identity and values.
<br/><br/>
<b>Brand Guidelines :</b> We develop comprehensive brand guidelines that ensure consistency in color, typography, and logo usage.
<br/><br/>
<b>Custom Graphics :</b> Our graphic designers craft custom visual elements that enhance your brand's appeal.
<br/><br/>
<b>Print Collaterals :</b>
<br/><br/>
<b>Business Cards :</b> We design professional and memorable business cards that leave a lasting impression.
<br/><br/>
<b>Brochures and Flyers :</b> We create informative and visually appealing brochures and flyers that convey your brand's message effectively.
<br/><br/>
<b>Brochures and Flyers :</b> We create informative and visually appealing brochures and flyers that convey your brand's message effectively. Signage and Banners: Our designers develop eye-catching signs and banners for both indoor and outdoor use.
<br/><br/>
<b>Digital Creatives :</b>
<br/><br/>
<b>Digital Ad Creatives :</b> We produce engaging digital ad creatives for your online advertising campaigns.
<br/><br/>
<b>Infographics :</b> We design informative and visually appealing infographics that explain complex concepts with clarity.
<br/><br/>
<b>Social Media Graphics :</b> Our team creates social media graphics that capture your audience's attention and enhance engagement.`
            },
            {
                id : 3,
                heading : "Why Creatives & Collaterals Matter",
                para : `
                    <b>Brand Consistency :</b> A cohesive visual identity across all creatives and collaterals enhances brand recognition and trust.
<br/><br/>
<b>Professionalism :</b> Well-designed print collaterals and digital creatives convey professionalism and competence.
<br/><br/>
<b>Memorability :</b> Eye-catching design elements make your brand memorable.
<br/><br/>
<b>Audience Engagement :</b> Quality creatives enhance engagement and foster a stronger connection with your audience.
                `
            },
            {
                id : 4,
                heading : "Our Commitment To Your Brand's Visual Identity",
                para : `
                   At DUMOSH, we are committed to shaping your brand's visual identity through expert creatives and collateral design. We understand that each brand has its unique story, and our services are tailored to align with your specific branding goals and objectives.
                `
            },
            {
                id : 5,
                heading : "Ready To Elevate Your Brand's Visual Identity?",
                para : `
                  Are you ready to elevate your brand's visual identity through well-crafted creatives and collaterals? Contact us today to explore how our experts can help you create a cohesive brand presence, enhance your brand's recognition, and achieve your branding objectives. Your brand's visual identity and success are our mission, and we're excited to transform your brand's visual elements into works of art.
                `
            },
            
            
        ]
    }

    const cimanagement = {
        heading : "Celebrity & Influencer Management",
        para : `Unlock Star Potential: From A-list Allure to Unforgettable Events. Your Image, Perfected. Celebrity & Influencer Management Services

In the dynamic world of marketing and brand building, the right partnerships can make all the difference. At DUMOSH, our "Celebrity & Influencer Management Services" are designed to connect your brand with the voices that resonate with your target audience. We specialize in managing partnerships with celebrities and influencers who can elevate your brand's presence and reach. Let's explore how our expertise in celebrity and influencer management can take your brand to new heights.
`,

        accordianList : [
            {
                id : 1,
                heading : "The Power Of Influencer And Celebrity Partnerships",
                para : `
                    In today's digital age, influencers and celebrities wield significant influence over their followers and fans. Partnering with the right individuals offers several advantages:
<br/><br/>
<b>Authenticity :</b> Influencers and celebrities can lend authenticity and credibility to your brand.
<br/><br/>
<b>Extended Reach :</b> Their large and engaged audiences provide an opportunity to expand your brand's reach.
<br/><br/>
<b>Targeted Messaging :</b> Partnering with the right influencers ensures your message resonates with a specific audience.
<br/><br/>
<b>Engagement :</b> Influencer and celebrity endorsements often lead to increased engagement and interaction with your brand.
                `
            },
            {
                id : 2,
                heading : "Our Celebrity & Influencer Management Services",
                para : `
                  At DUMOSH, we understand the potential of influencer and celebrity partnerships in building brand recognition and trust. Our services encompass a wide array of management solutions:
<br/><br/>
<b>Influencer Marketing :</b>
<br/><br/>
<b>Identifying the Right Influencers :</b> We research and identify influencers who align with your brand's values and target audience.
<br/><br/>
<b>Campaign Development :</b> We work with influencers to develop and execute campaigns that convey your brand's message effectively.
<br/><br/>
<b>Performance Monitoring :</b> We continually track and measure the performance of influencer campaigns to ensure they meet your goals.
<br/><br/>
<b>Celebrity Partnerships :</b>
<br/><br/>
<b>Celebrity Endorsements :</b> We facilitate endorsements and partnerships with celebrities who resonate with your brand.
<br/><br/>
<b>Campaign Creation :</b> We work with celebrities to create impactful campaigns that elevate your brand's presence.
<br/><br/>
<b>Event Collaborations :</b> We manage collaborations with celebrities for events, product launches, and more.`
            },
            {
                id : 3,
                heading : "Why Celebrity & Influencer Management Matters",
                para : `
                    <b>Trust and Credibility :</b> Influencers and celebrities bring their trust and credibility to your brand, enhancing your reputation.
<br/><br/>
<b>Extended Reach :</b> Partnering with influencers and celebrities allows your brand to reach a wider and more targeted audience.
<br/><br/>
<b>Engagement :</b> Influencer and celebrity endorsements often lead to increased engagement and interaction with your brand.
<br/><br/>
<b>Memorability :</b> Celebrity and influencer associations can make your brand more memorable.
                `
            },
            {
                id : 4,
                heading : "Our Commitment To Your Brand's Visual Identity",
                para : `
                  At DUMOSH, we are dedicated to enhancing your brand's presence through expert celebrity and influencer management. We understand that each brand has its unique identity and target audience, and our services are custom-tailored to align with your specific brand and marketing goals.
                `
            },
            {
                id : 5,
                heading : "Ready To Amplify Your Brand",
                para : `
                  Are you ready to amplify your brand through strategic celebrity and influencer partnerships? Contact us today to explore how our experts can help you identify and manage the right influencers and celebrities, extend your brand's reach, and achieve your marketing objectives. Your brand's success and amplification are our mission, and we're excited to connect your brand with influential voices.
                `
            },
            
            
        ]
    }

    useGSAP(() => {
        gsap.from(equalsSvg.current, {
            translateY : (windiwWidth > 990) ? "-86vh" : ( windiwWidth > 600) ? "-50vh" : "-55vh",
            translateX : (windiwWidth > 990) ? "34.5vw" : ( windiwWidth > 600) ? "-25vw" : "-14vw",

        scrollTrigger : { 
            scroller : 'body',
            start : 'top 0%',
            end :  (windiwWidth > 990) ? "top -100%" : ( windiwWidth > 600) ? "top -50%" : "top -50%",
            scrub : 2,                
        },
        })


        gsap.from(path1.current , {
            attr : {
                fill : "#fff"
            },
            
            scrollTrigger : { 
                scroller : 'body',
                start : 'top 0%',
                end : "top -100%",
                scrub : 2,                
            },

        })

        gsap.from(path2.current , {
            attr : {
                fill : "#fff"
            },
            scrollTrigger : { 
                scroller : 'body',
                start : 'top 0%',
                end : "top -100%",
                scrub : 2,                
            },
        })

        gsap.from(path3.current , {
            attr : {
                fill : "#fff"
            },
            scrollTrigger : {
                scroller : 'body',
                start : 'top 0%',
                end : "top -100%",
                scrub : 2,                
            },
        })

    })


  return (
    <>
    <CustomerAcquisitionBanner heading={"Design"} image={whiteEquals} backgroundImage={backgroundImage}  />
     <div className="container">
        <section className='service' style={{paddingTop : (windiwWidth > 990)? menuHeight + 50 : null}}>   
            <svg 
                viewBox="0 0 184 189" 
                fill="none" 
                className='svg'
                width={151.6}
                height={155.6}
                ref={equalsSvg}
            >
                <g>
                    <path d="M7,14 L145,14 L146,14 L146,14 L147,14 L147,15 L148,15 L148,16 L149,16 L149,17 L149,17 L149,18 L149,42 L149,43 L149,43 L149,44 L148,44 L148,45 L147,45 L147,46 L146,46 L146,46 L145,46 L7,46 L6,46 L6,46 L5,46 L5,45 L4,45 L4,44 L3,44 L3,43 L3,43 L3,42 L3,18 L3,18 L3,17 L3,17 L3,16 L4,16 L4,15 L5,15 L5,14 L6,14 L6,14 L7,14" fill="#0031FF"
                        ref={path1}
                    />

                    <path d="M7,63 L12,63 L16,63 L21,63 L25,63 L30,63 L34,63 L42,63 L47,63 L51,63 L59,63 L68,63 L76,63 L85,63 L94,63 L103,63 L111,63 L120,63 L128,63 L137,63 L145,63 L146,63 L146,63 L147,63 L147,64 L148,64 L148,65 L149,65 L149,66 L149,66 L149,67 L149,73 L149,79 L149,85 L149,91 L149,92 L149,92 L149,93 L148,93 L148,94 L147,94 L147,95 L146,95 L146,95 L145,95 L137,95 L128,95 L120,95 L111,95 L103,95 L94,95 L85,95 L76,95 L68,95 L59,95 L51,95 L42,95 L34,95 L25,95 L16,95 L7,95 L6,95 L6,95 L5,95 L5,94 L4,94 L4,93 L3,93 L3,92 L3,92 L3,91 L3,85 L3,79 L3,73 L3,67 L3,67 L3,66 L3,66 L3,65 L4,65 L4,64 L5,64 L5,63 L6,63 L6,63 L7,63" fill="#000"
                        ref={path2}
                    />

                    <path d="M7,63 L12,63 L16,63 L21,63 L25,63 L30,63 L34,63 L42,63 L47,63 L51,63 L59,63 L68,63 L76,63 L85,63 L94,63 L103,63 L111,63 L120,63 L128,63 L137,63 L145,63 L146,63 L146,63 L147,63 L147,64 L148,64 L148,65 L149,65 L149,66 L149,66 L149,67 L149,73 L149,79 L149,85 L149,91 L149,92 L149,92 L149,93 L148,93 L148,94 L147,94 L147,95 L146,95 L146,95 L145,95 L137,95 L128,95 L120,95 L111,95 L103,95 L94,95 L85,95 L76,95 L68,95 L59,95 L51,95 L42,95 L34,95 L25,95 L16,95 L7,95 L6,95 L6,95 L5,95 L5,94 L4,94 L4,93 L3,93 L3,92 L3,92 L3,91 L3,85 L3,79 L3,73 L3,67 L3,67 L3,66 L3,66 L3,65 L4,65 L4,64 L5,64 L5,63 L6,63 L6,63 L7,63" fill="#000"
                        ref={path3}

                    style={{opacity : 0}}
                    />
                </g>
            </svg>

            <CustomerAcquisition data={Design} />
        </section>

        

        <div className="piechart">
            <div className="legends desktop-legends">
                <div className="legend">
                    <div className="box box-1"></div>
                    <h3>New Brand</h3>
                </div>

                <div className="legend">
                    <div className="box box-2"></div>
                    <h3>Go To Market</h3>
                </div>

                <div className="legend">
                    <div className="box box-3"></div>
                    <h3>Deep Stick Market </h3>
                </div>

                <div className="legend">
                    <div className="box box-4"></div>
                    <h3>Media & Campaign</h3>
                </div>
            </div>
            <PiechartDesign />

            <div className="legends desktop-legends">
                <div className="legend">
                    <div className="box box-5"></div>
                    <h3>Print, OOH, Mainline </h3>
                </div>

                <div className="legend">
                    <div className="box box-6"></div>
                    <h3>Film Production </h3>
                </div>

                <div className="legend">
                    <div className="box box-7"></div>
                    <h3>Copywriting & Original</h3>
                </div>

                <div className="legend">
                    <div className="box box-8"></div>
                    <h3>Creatives & Co-Laterals </h3>
                </div>

                <div className="legend">
                    <div className="box box-9"></div>
                    <h3>Celebrity & Influencer</h3>
                </div>
            </div>


            <div className="legends mobile-legends">

            <div className="legend">
                    <div className="box box-1"></div>
                    <h3>New Brand</h3>
                </div>

                <div className="legend">
                    <div className="box box-2"></div>
                    <h3>Go To Market</h3>
                </div>

                <div className="legend">
                    <div className="box box-3"></div>
                    <h3>Deep Stick Market </h3>
                </div>

                <div className="legend">
                    <div className="box box-4"></div>
                    <h3>Media & Campaign</h3>
                </div>

                <div className="legend">
                    <div className="box box-5"></div>
                    <h3>Print, OOH, Mainline </h3>
                </div>

                <div className="legend">
                    <div className="box box-6"></div>
                    <h3>Film Production </h3>
                </div>

                <div className="legend">
                    <div className="box box-7"></div>
                    <h3>Copywriting & Original</h3>
                </div>

                <div className="legend">
                    <div className="box box-8"></div>
                    <h3>Creatives & Co-Laterals </h3>
                </div>

                <div className="legend">
                    <div className="box box-9"></div>
                    <h3>Celebrity & Influencer</h3>
                </div>
            </div>


        </div>
        </div>
        <GraphDetailedInfo mode={"dark"} dataObj={EPR} />
        <GraphDetailedInfo mode={"light"} dataObj={CRM} />
        {/* <GraphDetailedInfo mode={"dark"} dataObj={paymentGateway} />
        <GraphDetailedInfo mode={"light"} dataObj={ecommerce} />
        <GraphDetailedInfo mode={"dark"} dataObj={appDevelopment} />
        <GraphDetailedInfo mode={"light"} dataObj={UIUX} />
        <GraphDetailedInfo mode={"dark"} dataObj={automation} />
        <GraphDetailedInfo mode={"light"} dataObj={security} />
        <GraphDetailedInfo mode={"dark"} dataObj={cimanagement} /> */}
    </>
  )
}

export default Design