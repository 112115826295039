import React, { useRef, useState } from 'react'
import boxes from "../assets/images/Group1.webp";
import add from "../assets/images/Group1.webp";
import raisedToImg from "../assets/images/Group2.webp";
import equalsImg from "../assets/images/Group3.webp";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import '../styles/homeBanner.css'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(useGSAP, ScrollTrigger);

function HomeBannerUpdated() {

    const [plusSvgHide, setPlusSvgHide] = useState(false);

    const [raisedToSVGHide, setRaisedToSVGHide] = useState(true);
    const [equalsToSVGHide, setEqualsToSVGHide] = useState(false);

    const windiwWidth = window.innerWidth
    const windiwHeight = window.innerHeight

    // Animetion X to Plus
    const xToPlus = useRef(null);
    const Xpath1 = useRef(null);
    const Xpath2 = useRef(null);
    const Xpath3 = useRef(null);

    // Animetion Plus to raisedTo 
    const plusToRaised = useRef(null);
    const plusPath1 = useRef(null);
    const plusPath2 = useRef(null);
    const plusPath3 = useRef(null);


    // Animetion Plus to raisedTo 
    const raisedToEquals = useRef(null);
    const raisedPath1 = useRef(null);
    const raisedPath2 = useRef(null);
    const raisedPath3 = useRef(null);

    const imageContainer = useRef(null);
    const technology = useRef(null);
    const design = useRef(null);

    const homeBannerPage = useRef(null);
    const ourServicesConatiner = useRef(null);
    

    useGSAP(() => {


        gsap.from(xToPlus.current , {
            
            translateY : (windiwWidth > 1024 && windiwHeight > 900) ? "-520%" : (windiwWidth > 1024) ? "-120vh" : (windiwWidth > 990) ? "-135vh" : (windiwWidth > 768) ? "-400%" : (windiwWidth > 500) ? "-400%" : (windiwWidth > 400) ? "-400%" : (windiwWidth > 360) ? "-400%" : "-400%" ,
            translateX : (windiwWidth > 990) ? "62vw" : "0vw",
            scale : (windiwWidth > 1024) ? 1.8 : (windiwWidth > 990) ? 1.5 : (windiwWidth > 425) ? 1.2 : 1,

            scrollTrigger : {
                trigger : homeBannerPage.current,
                // markers : true, 
                scroller : 'body',
                start : 'top 0%',
                end : "top -140%",
                scrub : 2,
                
                onLeave : () => {setPlusSvgHide(true)},
            },

        })

        gsap.from(Xpath1.current , {
            attr : {
                d : "M158.624 0.293091C149.425 0.436219 141.209 0.644296 140.368 0.75535C138.548 0.995902 136.852 1.79573 135.792 2.91329C134.26 4.5296 91.738 60.2551 91.2903 61.2335C90.2458 63.5168 90.2089 65.8479 91.1868 67.7834C91.9739 69.3409 111.328 94.1937 112.259 94.8424C113.455 95.6753 114.676 96.0493 116.212 96.0531C118.159 96.058 119.616 95.46 120.915 94.1229C122.643 92.3442 183.805 12.8008 184.367 11.6008C185.054 10.136 185.202 7.76622 184.712 6.07233C183.632 2.33156 180.286 -0.107226 176.386 0.00362773C175.816 0.0198649 167.823 0.150164 158.624 0.293091Z",
                fill : "#fff"
            },
            
            scrollTrigger : {
                trigger : imageContainer.current,
                scroller : 'body',
                start : 'top 70%',
                end : "top 68%",
                scrub: 1,
            }

        })

        gsap.from(Xpath2.current , {
            attr : {
                d : "M26.4895 33.8704C5.00706 34.1218 6.76617 34.0456 5.18672 34.7932C1.72224 36.4325 -0.0067974 39.8277 2.00815e-05 44.9773C0.00342882 47.5752 0.294374 49.0839 1.12089 50.7908C1.38798 51.3421 12.258 65.3468 25.2766 81.9123C38.2954 98.4779 48.9471 112.076 48.9471 112.131C48.9471 112.186 38.361 126.416 25.4224 143.753C12.484 161.091 1.65386 175.637 1.3555 176.078C0.0274925 178.041 -0.370532 181.481 0.430121 184.073C1.29734 186.88 3.72016 189.387 6.3501 190.199C7.61194 190.589 8.36648 190.61 25.8485 190.737C42.2457 190.856 44.1579 190.837 45.2876 190.544C46.9747 190.106 48.0109 189.55 49.1849 188.452C49.7255 187.947 56.6302 179.154 64.5287 168.913L78.8895 150.293L79.3294 150.757C79.5714 151.012 86.3617 159.618 94.4187 169.882C102.476 180.145 109.359 188.788 109.715 189.087C110.652 189.875 112.119 190.571 113.559 190.91C115.474 191.362 149.366 191.357 151.102 190.905C152.766 190.472 154.116 189.689 155.464 188.375C158.134 185.772 158.887 181.838 157.355 178.492C156.851 177.392 49.6762 37.9686 47.9929 36.224C46.334 34.5047 43.7668 33.6086 40.7261 33.688C40.1195 33.7039 33.7131 33.786 26.4895 33.8704Z",
                fill : "#fff"
            },
            scrollTrigger : {
                trigger : imageContainer.current,
                scroller : 'body',
                start : 'top 70%',
                end : "top 68%",

                scrub: 1,
            }
        })

        gsap.from(Xpath3.current , {
            opacity : 0,

            scrollTrigger : {
                trigger : imageContainer.current,
                scroller : 'body',
                start : 'top 70%',
                end : "top 68%",

                scrub: 1,
            }

        })
    })

    useGSAP(() => {
        gsap.from(plusToRaised.current , {
            translateY : (windiwWidth > 990) ? -518 : "-400%",
            translateX : (windiwWidth > 990) ? null : "9%",

            scrollTrigger : {
                trigger : imageContainer.current,
                // markers : true,
                scroller : 'body',
                start : (windiwWidth > 990) ? 'top 30%' : 'top 10%',
                end : (windiwWidth > 990) ? 'top -20%' : 'top -50%',
                scrub : 2,
                onLeave : () => {setRaisedToSVGHide(false)},
                onLeaveBack : () => {setPlusSvgHide(false)},
            },

        })

        gsap.from(plusPath1.current , {
            attr : {
                d : "M4,67 L148,67.4074 L149,67 L149,68 L150,68 L150,68 L151,69 L151,69 L152,70 L152,70 L152,71 L152,71 L152,96.1481 L152,97 L152,97 L152,98 L151,99 L151,99 L150,99 L150,100 L149,100 L149,100 L148,100 L4,100.148 L3,100 L3,100 L2,100 L2,99 L1,99 L1,99 L0,98 L0,97 L0,97 L0,96 L0,71.4074 L0,71.4074 L0,71 L0,70 L0,70 L1,69 L1,69 L2,68 L2,68 L3,68 L3,67 L4,67",
                fill : "#000"
            },
            
            scrollTrigger : {
                trigger : imageContainer.current,
                // markers : true, 
                scroller : 'body',
                start : (windiwWidth > 990) ? 'top 30%' : 'top 10%',
                end : (windiwWidth > 990) ? 'top -20%' : 'top -100%',
                scrub : 2,
            }

        })

        gsap.from(plusPath2.current , {
            attr : {
                d : "M61,78 L65,78 L68,78 L74,78 L78,78 L81,78 L87,78 L88,78 L88,78 L89,78 L89,79 L90,79 L90,80 L91,80 L91,81 L91,81 L91,82 L91,87 L91,91 L91,96 L91,100 L91,105 L91,109 L91,113 L91,117 L91,122 L91,126 L91,131 L91,135 L91,140 L91,144 L91,148 L91,152 L91,153 L91,153 L91,154 L90,154 L90,155 L89,155 L89,156 L88,156 L88,156 L87,156 L81,156 L78,156 L74,156 L68,156 L65,156 L61,156 L61,156 L60,156 L60,156 L59,156 L59,155 L58,155 L58,154 L57,154 L57,153 L57,153 L57,152 L57,148 L57,144 L57,140 L57,135 L57,131 L57,126 L57,122 L57,117 L57,113 L57,109 L57,105 L57,100 L57,96 L57,91 L57,87 L57,82 L57,81 L57,81 L57,80 L58,80 L58,79 L59,79 L59,78 L60,78 L60,78 L61,78",
                fill : "#000"
            },
            scrollTrigger : {
                trigger : imageContainer.current,
                // markers : true, 
                scroller : 'body',
                start : (windiwWidth > 990) ? 'top 30%' : 'top 10%',
                end : (windiwWidth > 990) ? 'top -20%' : 'top -100%',
                scrub : 2,
            }
        })

        gsap.from(plusPath3.current , {
            attr : {
                d : "M61,0 L65,0 L68,0 L71,0 L74,0 L78,0 L81,0 L84,0 L87,0 L88,0 L88,0 L89,0 L89,1 L90,1 L90,2 L91,2 L91,3 L91,3 L91,4 L91,8 L91,11 L91,14 L91,17 L91,21 L91,24 L91,27 L91,30 L91,34 L91,37 L91,40 L91,43 L91,46 L91,49 L91,52 L91,55.7037 L91,56 L91,57 L91,58 L90,58 L90,59 L89,59 L89,59 L88,59 L88,60 L87,60 L84,60 L81,60 L78,60 L74,60 L71,60 L68,60 L65,60 L61,59.7037 L61,59.7037 L60,60 L60,59 L59,59 L59,59 L58,59 L58,58 L57,58 L57,57 L57,56 L57,56 L57,50 L57,47 L57,43 L57,37 L57,34 L57,30 L57,24 L57,21 L57,17 L57,11 L57,8 L57,4 L57,3 L57,3 L57,2 L58,2 L58,1 L59,1 L59,0 L60,0 L60,0 L61,0",
                fill : "#0031FF"
            },

            scrollTrigger : {
                trigger : imageContainer.current,
                // markers : true, 
                scroller : 'body',
                start : (windiwWidth > 990) ? 'top 30%' : 'top 10%',
                end : (windiwWidth > 990) ? 'top -20%' : 'top -100%',
                scrub : 2,
            }

        })
    })

    useGSAP(() => {
        gsap.from(raisedToEquals.current , {
            translateY : (windiwWidth > 990) ? -452 : "-390%",

            scrollTrigger : {
                trigger : (windiwWidth > 990) ? design.current : technology.current,
                // markers : true, 
                scroller : 'body',
                start : (windiwWidth > 990) ? "top 90%" : "top 50%",
                end : (windiwWidth > 990) ? "top 60%" : "top 0%",
                scrub : 2,
                onLeave : () => {setEqualsToSVGHide(true)},
                onLeaveBack : () => {setRaisedToSVGHide(true)}
            },

        })

        gsap.from(raisedPath1.current , {
            attr : {
                d : "M65.4535,41.744 L71,42 L76,42 L81,42 L86.3815,41.744 L89,48 L91,52 L92,55 L94,59 L95,62 L98,68 L100,72 L101,75 L104,81 L106,85 L107,88 L109.806,94.448 L104,94 L99,94 L94,94 L88.6855,94.448 L87,91 L85,87 L82,80 L79,73 L77,69 L75.9175,65.84 L74,69 L72,73 L69,80 L66,87 L64,91 L62.9575,94.448 L62.9575,94.448 L57,94 L52,94 L47,94 L41.8375,94.448 L45,88 L48,81 L51,75 L54,68 L57,62 L60,55 L63,48 L65.4535,41.744",
                fill : "#0031FF"
            },
            
            scrollTrigger : {
                trigger : (windiwWidth > 990) ? design.current : technology.current,
                // markers : true, 
                scroller : 'body',
                start : (windiwWidth > 990) ? "top 90%" : "top 50%",
                end : (windiwWidth > 990) ? "top 60%" : "top 0%",
                scrub : 2,
            }

        })

        gsap.from(raisedPath2.current , {
            attr : {
                d : "M20,24 L40.6,24.0799561 L40.6,26.095959999999998 L39,28 L37,30 L35,32 L34,35 L32,37 L31,39 L29,42 L28,44 L27,47 L26,50 L25,52 L24,55 L23,58 L22,61 L22,64 L21,66 L21,69 L21,72 L21,75 L21,78 L21,81 L21,84 L21,87 L21,90 L22,93 L22,95 L23,98 L24,101 L25,104 L26,107 L27,109 L28,112 L29,114 L31,117 L32,119 L34,122 L35,124 L37,126 L39,128 L41,130 L40.6,131.98399999999998 L20.056,131.98399999999998 L20.056,131.98399999999998 L18,130 L17,128 L15,126 L13,124 L12,122 L10,119 L9,117 L8,114 L7,112 L5,109 L4,106 L4,103 L3,100 L2,97 L2,94 L1,91 L1,88 L0,85 L0,81 L0,78 L0,75 L0,71 L1,68 L1,65 L2,62 L2,59 L3,56 L4,53 L4,50 L5,47 L7,44 L8,42 L9,39 L10,37 L12,34 L13,32 L15,30 L17,28 L18,26 L20,24",
                fill : "#000"
            },
            scrollTrigger : {
                trigger : (windiwWidth > 990) ? design.current : technology.current,
                // markers : true, 
                scroller : 'body',
                start : (windiwWidth > 990) ? "top 90%" : "top 50%",
                end : (windiwWidth > 990) ? "top 60%" : "top 0%",
                scrub : 2,
            }
        })

        gsap.from(raisedPath3.current , {
            attr : {
                d : "M131.58,24.0799561 L133,26 L135,28 L137,30 L138,32 L140,34 L141,37 L143,39 L144,42 L145,44 L146,47 L147,50 L148,53 L149,56 L150,59 L150,62 L151,65 L151,68 L151,71 L151,75 L151,78 L151,81 L151,85 L151,88 L151,91 L150,94 L150,97 L149,100 L148,103 L147,106 L146,109 L145,112 L144,114 L143,117 L141,119 L140,122 L138,124 L137,126 L135,128 L133,130 L132,132 L111.035,131.98399999999998 L111.035,131.98399999999998 L111.035,130.064 L113,128 L115,126 L116,124 L118,122 L119,119 L121,117 L122,114 L123,112 L125,109 L126,107 L127,104 L128,101 L128,98 L129,95 L130,93 L130,90 L131,87 L131,84 L131,81 L131,78 L131,75 L131,72 L131,69 L130,66 L130,64 L129,61 L128,58 L128,55 L127,52 L126,50 L125,47 L123,44 L122,42 L121,39 L119,37 L118,35 L116,32 L115,30 L113,28 L111,26 L111.035,24.0799561 L131.58,24.0799561",
                fill : "#000"
            },
            opacity :1,

            scrollTrigger : {
                trigger : (windiwWidth > 990) ? design.current : technology.current,
                // markers : true, 
                scroller : 'body',
                start : (windiwWidth > 990) ? "top 90%" : "top 50%",
                end : (windiwWidth > 990) ? "top 60%" : "top 0%",
                scrub : 2,
            }

        })
    })



  return (
        <>
            <div className="prodmax-banner">

                <div className="container">
                    <section className='home-banner' ref={homeBannerPage}>
                        <div className="banner-heading">
                            <h1>Where Innovation Meets</h1>
                            <h1>Digital Excellence</h1>
                        </div>

                        <div className="banner-image-container" >
                            {/* <img src={prodmaxLogo} alt="logo" className='logo-banner'
                                style={{display : plusSvgHide ? 'block' : 'none'}}
                            /> */}
                            <img src={boxes} alt="boxes" className='boxes' style={{opacity : 0}} />
                        </div>
                    </section>
                </div>
            </div>

            <section className='our-services' ref={ourServicesConatiner}>
                <div className="container">

                    <div className="heading">
                        <h1 style={{position: 'relative', zIndex : 100}}>Our Services</h1>
                        <p style={{position: 'relative', zIndex : 100}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                    </div>


                    <div className="customer-aq">
                        <h2  style={{position: 'relative', zIndex : 100}}>Customer Acquisition </h2>


                        <div className="animation-container" >
                            <div className="image-container"  ref={imageContainer} >
                                <img src={add} alt="add" style={{opacity : 0}}  />

                                <svg 
                                    viewBox="0 0 184 189" 
                                    fill="none" 
                                    ref={xToPlus} 
                                    className='svg'
                                    style={{opacity : plusSvgHide ? 0 : 1}}
                                >
                                    <g>
                                        <path ref={Xpath1} d="M4,67 L148,67.4074 L149,67 L149,68 L150,68 L150,68 L151,69 L151,69 L152,70 L152,70 L152,71 L152,71 L152,96.1481 L152,97 L152,97 L152,98 L151,99 L151,99 L150,99 L150,100 L149,100 L149,100 L148,100 L4,100.148 L3,100 L3,100 L2,100 L2,99 L1,99 L1,99 L0,98 L0,97 L0,97 L0,96 L0,71.4074 L0,71.4074 L0,71 L0,70 L0,70 L1,69 L1,69 L2,68 L2,68 L3,68 L3,67 L4,67" fill="#000"/>

                                        <path ref={Xpath2} d="M61,78 L65,78 L68,78 L74,78 L78,78 L81,78 L87,78 L88,78 L88,78 L89,78 L89,79 L90,79 L90,80 L91,80 L91,81 L91,81 L91,82 L91,87 L91,91 L91,96 L91,100 L91,105 L91,109 L91,113 L91,117 L91,122 L91,126 L91,131 L91,135 L91,140 L91,144 L91,148 L91,152 L91,153 L91,153 L91,154 L90,154 L90,155 L89,155 L89,156 L88,156 L88,156 L87,156 L81,156 L78,156 L74,156 L68,156 L65,156 L61,156 L61,156 L60,156 L60,156 L59,156 L59,155 L58,155 L58,154 L57,154 L57,153 L57,153 L57,152 L57,148 L57,144 L57,140 L57,135 L57,131 L57,126 L57,122 L57,117 L57,113 L57,109 L57,105 L57,100 L57,96 L57,91 L57,87 L57,82 L57,81 L57,81 L57,80 L58,80 L58,79 L59,79 L59,78 L60,78 L60,78 L61,78" fill="#000"/>

                                        <path ref={Xpath3} d="M61,0 L65,0 L68,0 L71,0 L74,0 L78,0 L81,0 L84,0 L87,0 L88,0 L88,0 L89,0 L89,1 L90,1 L90,2 L91,2 L91,3 L91,3 L91,4 L91,8 L91,11 L91,14 L91,17 L91,21 L91,24 L91,27 L91,30 L91,34 L91,37 L91,40 L91,43 L91,46 L91,49 L91,52 L91,55.7037 L91,56 L91,57 L91,58 L90,58 L90,59 L89,59 L89,59 L88,59 L88,60 L87,60 L84,60 L81,60 L78,60 L74,60 L71,60 L68,60 L65,60 L61,59.7037 L61,59.7037 L60,60 L60,59 L59,59 L59,59 L58,59 L58,58 L57,58 L57,57 L57,56 L57,56 L57,50 L57,47 L57,43 L57,37 L57,34 L57,30 L57,24 L57,21 L57,17 L57,11 L57,8 L57,4 L57,3 L57,3 L57,2 L58,2 L58,1 L59,1 L59,0 L60,0 L60,0 L61,0" fill="#0031FF"/>
                                    </g>
                                </svg>


                            </div>

                            <div className="info-container">
                                <h3 style={{position: 'relative', zIndex : 100}}><span>Marketing <span style={{fontWeight: 700}}>+</span>  Sales</span></h3>
                                <p className='smaller' style={{position: 'relative', zIndex : 100}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                                <p className='smaller'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt </p>
                            </div>

                        </div>

                    </div>

                    

                    <div className="customer-aq">
                        <h2 style={{position: 'relative', zIndex : 100}} >Technology</h2>


                        <div className="animation-container">
                            <div className="image-container">
                                <img src={raisedToImg} alt="raised-to" style={{opacity : 0}}/>
                                

                                <svg 
                                    viewBox="0 0 184 189" 
                                    fill="none" 
                                    ref={plusToRaised}
                                    className='raised'
                                    style={{opacity : (plusSvgHide && raisedToSVGHide) ? 1 : 0}}
                                >
                                    <g>
                                        <path d="M65.4535,41.744 L71,42 L76,42 L81,42 L86.3815,41.744 L89,48 L91,52 L92,55 L94,59 L95,62 L98,68 L100,72 L101,75 L104,81 L106,85 L107,88 L109.806,94.448 L104,94 L99,94 L94,94 L88.6855,94.448 L87,91 L85,87 L82,80 L79,73 L77,69 L75.9175,65.84 L74,69 L72,73 L69,80 L66,87 L64,91 L62.9575,94.448 L62.9575,94.448 L57,94 L52,94 L47,94 L41.8375,94.448 L45,88 L48,81 L51,75 L54,68 L57,62 L60,55 L63,48 L65.4535,41.744" fill="#0031FF"
                                            ref={plusPath1}
                                        />

                                        <path d="M20,24 L40.6,24.0799561 L40.6,26.095959999999998 L39,28 L37,30 L35,32 L34,35 L32,37 L31,39 L29,42 L28,44 L27,47 L26,50 L25,52 L24,55 L23,58 L22,61 L22,64 L21,66 L21,69 L21,72 L21,75 L21,78 L21,81 L21,84 L21,87 L21,90 L22,93 L22,95 L23,98 L24,101 L25,104 L26,107 L27,109 L28,112 L29,114 L31,117 L32,119 L34,122 L35,124 L37,126 L39,128 L41,130 L40.6,131.98399999999998 L20.056,131.98399999999998 L20.056,131.98399999999998 L18,130 L17,128 L15,126 L13,124 L12,122 L10,119 L9,117 L8,114 L7,112 L5,109 L4,106 L4,103 L3,100 L2,97 L2,94 L1,91 L1,88 L0,85 L0,81 L0,78 L0,75 L0,71 L1,68 L1,65 L2,62 L2,59 L3,56 L4,53 L4,50 L5,47 L7,44 L8,42 L9,39 L10,37 L12,34 L13,32 L15,30 L17,28 L18,26 L20,24" fill="#000"
                                            ref={plusPath2}
                                        />

                                        <path d="M131.58,24.0799561 L133,26 L135,28 L137,30 L138,32 L140,34 L141,37 L143,39 L144,42 L145,44 L146,47 L147,50 L148,53 L149,56 L150,59 L150,62 L151,65 L151,68 L151,71 L151,75 L151,78 L151,81 L151,85 L151,88 L151,91 L150,94 L150,97 L149,100 L148,103 L147,106 L146,109 L145,112 L144,114 L143,117 L141,119 L140,122 L138,124 L137,126 L135,128 L133,130 L132,132 L111.035,131.98399999999998 L111.035,131.98399999999998 L111.035,130.064 L113,128 L115,126 L116,124 L118,122 L119,119 L121,117 L122,114 L123,112 L125,109 L126,107 L127,104 L128,101 L128,98 L129,95 L130,93 L130,90 L131,87 L131,84 L131,81 L131,78 L131,75 L131,72 L131,69 L130,66 L130,64 L129,61 L128,58 L128,55 L127,52 L126,50 L125,47 L123,44 L122,42 L121,39 L119,37 L118,35 L116,32 L115,30 L113,28 L111,26 L111.035,24.0799561 L131.58,24.0799561" fill="#000"
                                            ref={plusPath3}
                                        />
                                    </g>
                                </svg>
                            </div>

                            <div className="info-container" ref={technology}>
                                <h3><span>(Development)<sup style={{fontWeight: 700}}>^</sup></span></h3>
                                <p className='smaller'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                                <p className='smaller'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt </p>
                            </div>

                        </div>

                    </div>

                    <div className="customer-aq">
                        <h2 style={{position: 'relative', zIndex : 100}}>Design</h2>


                        <div className="animation-container">
                            <div className="image-container">
                                <img src={equalsImg} alt="equals" style={{opacity : 0}} />


                                <svg 
                                    viewBox="0 0 184 189" 
                                    fill="none" 
                                    className='equals'
                                    ref={raisedToEquals}
                                    style={{opacity : (raisedToSVGHide) ? 0 : 1
                                        // visibility: equalsToSVGHide ? "hidden" : "visible"
                                    }}
                                >
                                    <g>
                                        <path d="M7,14 L145,14 L146,14 L146,14 L147,14 L147,15 L148,15 L148,16 L149,16 L149,17 L149,17 L149,18 L149,42 L149,43 L149,43 L149,44 L148,44 L148,45 L147,45 L147,46 L146,46 L146,46 L145,46 L7,46 L6,46 L6,46 L5,46 L5,45 L4,45 L4,44 L3,44 L3,43 L3,43 L3,42 L3,18 L3,18 L3,17 L3,17 L3,16 L4,16 L4,15 L5,15 L5,14 L6,14 L6,14 L7,14" fill="#0031FF"
                                            ref={raisedPath1}
                                        />

                                        <path d="M7,63 L12,63 L16,63 L21,63 L25,63 L30,63 L34,63 L42,63 L47,63 L51,63 L59,63 L68,63 L76,63 L85,63 L94,63 L103,63 L111,63 L120,63 L128,63 L137,63 L145,63 L146,63 L146,63 L147,63 L147,64 L148,64 L148,65 L149,65 L149,66 L149,66 L149,67 L149,73 L149,79 L149,85 L149,91 L149,92 L149,92 L149,93 L148,93 L148,94 L147,94 L147,95 L146,95 L146,95 L145,95 L137,95 L128,95 L120,95 L111,95 L103,95 L94,95 L85,95 L76,95 L68,95 L59,95 L51,95 L42,95 L34,95 L25,95 L16,95 L7,95 L6,95 L6,95 L5,95 L5,94 L4,94 L4,93 L3,93 L3,92 L3,92 L3,91 L3,85 L3,79 L3,73 L3,67 L3,67 L3,66 L3,66 L3,65 L4,65 L4,64 L5,64 L5,63 L6,63 L6,63 L7,63" fill="#000"
                                            ref={raisedPath2}
                                        />

                                        <path d="M7,63 L12,63 L16,63 L21,63 L25,63 L30,63 L34,63 L42,63 L47,63 L51,63 L59,63 L68,63 L76,63 L85,63 L94,63 L103,63 L111,63 L120,63 L128,63 L137,63 L145,63 L146,63 L146,63 L147,63 L147,64 L148,64 L148,65 L149,65 L149,66 L149,66 L149,67 L149,73 L149,79 L149,85 L149,91 L149,92 L149,92 L149,93 L148,93 L148,94 L147,94 L147,95 L146,95 L146,95 L145,95 L137,95 L128,95 L120,95 L111,95 L103,95 L94,95 L85,95 L76,95 L68,95 L59,95 L51,95 L42,95 L34,95 L25,95 L16,95 L7,95 L6,95 L6,95 L5,95 L5,94 L4,94 L4,93 L3,93 L3,92 L3,92 L3,91 L3,85 L3,79 L3,73 L3,67 L3,67 L3,66 L3,66 L3,65 L4,65 L4,64 L5,64 L5,63 L6,63 L6,63 L7,63" fill="#000"
                                            ref={raisedPath3}

                                        style={{opacity : 0}}
                                        />
                                    </g>
                                </svg>
                            </div>

                            <div className="info-container" ref={design}>
                                <h3><span>Web Design + Logo Design <span style={{fontWeight: 700}}>=</span> Brand Kit   Sales</span></h3>
                                <p className='smaller'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                                <p className='smaller'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt </p>
                            </div>

                        </div>

                    </div>
                    

                </div>

            </section>

        </>
  )
}

//  Most Recent Gsap Code BAckUp

// useGSAP(() => {

//     if (!posOfX) return; 
//     if (!posOfRaised) return; 
//     if (!posOfEquals) return; 


//     const topPosition = (posOfX.top + posOfRaised.top + posOfEquals.top) / 2

//     // const t1 = gsap.timeline({
//     //     scrollTrigger: {
//     //         trigger: homeBannerPage.current,
//     //         scroller: 'body',
//     //         start: 'top 0%',
//     //         // end: (windiwWidth > 990) ? `top -${topPosition }` : "top -470%",
//     //         end: (windiwWidth > 990) ? `top -${topPosition}` : `top -${topPosition} ` ,
//     //         scrub: (windiwWidth > 990) ? 2 : 2,
//     //       }
//     // })

//     // t1.from(xToPlus.current , {
//     //     top : posOfX.top,
//     //     left : (windiwWidth > 990) ? posOfX.left : "50%",
//     //     translateX : (windiwWidth > 990) ? null : "-50%",
//     //     scale : (windiwWidth > 1024) ? 1.8 : (windiwWidth > 990) ? 1.5 : (windiwWidth > 425) ? 1.2 : 1,

//     // })

//     // t1.to(xToPlus.current, {
        
//     //     top : posOfEquals.top,
//     // })



//     const timeLine2 = gsap.timeline({
//         scrollTrigger : {
//                     trigger: technology.current,
//                     scroller: 'body',
//                     start: 'top 70%',
//                     end : `top ${posOfRaised.height}` ,
//                     scrub : 2,
//                 }
//     })

//     timeLine2.to(xToPlus.current , {
//             top : posOfRaised.top,
//         })


//         const timeLine3 = gsap.timeline({
//             scrollTrigger : {
//                 trigger: design.current,
//                 scroller: 'body',
//                 start: 'top 70%',
//                 end : `top ${posOfEquals.height}` ,
//                 scrub : 2,
//             }
//         })

//         timeLine3.to(xToPlus.current , {
//             top : posOfEquals.top,
//         })

//     const masterTimeLine = gsap.timeline({
//         scrollTrigger : {
//             trigger: homeBannerPage.current,
//             scroller: 'body',
//             start: 'top 0%',
//             end : "top -150%",
//             scrub : 2,
//         }
//     })

//     masterTimeLine.from(xToPlus.current , {
//             top : posOfX.top,
//             left : (windiwWidth > 990) ? posOfX.left : "50%",
//             translateX : (windiwWidth > 990) ? null : "-50%",
//             scale : (windiwWidth > 1024) ? 1.8 : (windiwWidth > 990) ? 1.5 : (windiwWidth > 425) ? 1.2 : 1,
//         }
//     ).add(timeLine2)



//     // gsap.from(xToPlus.current , {
//     //     top : posOfX.top,
//     //     left : (windiwWidth > 990) ? posOfX.left : "50%",
//     //     translateX : (windiwWidth > 990) ? null : "-50%",
//     //     scale : (windiwWidth > 1024) ? 1.8 : (windiwWidth > 990) ? 1.5 : (windiwWidth > 425) ? 1.2 : 1,

//     //     scrollTrigger : {
//     //         trigger: homeBannerPage.current,
//     //         scroller: 'body',
//     //         start: 'top 0%',
//     //         end : "top -150%",
//     //         scrub : 2,
//     //     }
//     // })

//     // gsap.to(xToPlus.current , {
//     //     top : posOfRaised.top,
        

//     //     scrollTrigger : {
//     //         trigger: homeBannerPage.current,
//     //         scroller: 'body',
//     //         start: 'top -152%',
//     //         end : "top -200%",
//     //         scrub : 2,
//     //     }
//     // })
   

// }, [posOfX, raisedPositionsRef, equalsPositionsRef])


// useGSAP(() => {

//     const timeLine2 = gsap.timeline({
//         scrollTrigger : {
//             trigger : (windiwWidth > 990) ? technology.current : technologyMobile.current,
//             scroller : 'body',
//             start : 'top 70%',
//             end : "top 68%",
//             scrub: 2,
//         }
//     })
//     timeLine2.to(Xpath1.current , {
//         attr : {
//             d : "M65.4535,41.744 L71,42 L76,42 L81,42 L86.3815,41.744 L89,48 L91,52 L92,55 L94,59 L95,62 L98,68 L100,72 L101,75 L104,81 L106,85 L107,88 L109.806,94.448 L104,94 L99,94 L94,94 L88.6855,94.448 L87,91 L85,87 L82,80 L79,73 L77,69 L75.9175,65.84 L74,69 L72,73 L69,80 L66,87 L64,91 L62.9575,94.448 L62.9575,94.448 L57,94 L52,94 L47,94 L41.8375,94.448 L45,88 L48,81 L51,75 L54,68 L57,62 L60,55 L63,48 L65.4535,41.744",
//             fill : "#0031FF"
//         },
        
        

//     })

//     timeLine2.to(Xpath2.current , {
//         attr : {
            
//             d : "M 20 25 L 40.132 24.961 L 40.132 26.833 L 39 28 L 37.352 30.123 L 35.537 32.335 L 34.006 34.377 L 32.304 37.043 L 31 39 L 29.241 42.205 L 28.334 44.247 L 27 47 L 25.668 49.919 L 25 52 L 24 55 L 23 58 L 22.202 61.358 L 21.725 63.564 L 21.129 66.008 L 20.903 68.807 L 20.532 71.911 L 20.294 74.951 L 20.294 77.932 L 20.234 80.853 L 20.473 83.417 L 20.711 86.457 L 21 90 L 21.486 92.836 L 22 95 L 22.738 97.844 L 23.573 101.123 L 24.527 103.985 L 25.719 107.085 L 26.554 109.112 L 28 112 L 28.879 114.061 L 30.727 117.697 L 31.741 119.426 L 33.47 122.05 L 35 124 L 36.808 126.403 L 38.656 128.489 L 40.147 130.04 L 40.028 131.888 L 20.056 131.984 L 20.056 131.984 L 18.267 130.517 L 16.776 128.906 L 14.988 126.76 L 13.14 124.256 L 12.007 122.646 L 9.98 119.606 L 9.026 117.638 L 7.893 115.372 L 6.641 112.868 L 5 109 L 4 106 L 3.362 103.687 L 2.587 100.647 L 1.991 97.129 L 1.514 95.042 L 0.799 90.988 L 0.501 88.246 L 0.202 85.503 L 0 81 L 0 78 L 0 75 L 0.322 71.016 L 0.56 67.976 L 1 65 L 1.395 62.014 L 2 59 L 2.408 57.244 L 3.124 54.383 L 4.257 50.746 L 5.449 47.288 L 7 44 L 7.655 42.042 L 9 39 L 9.92 37.63 L 11.828 34.649 L 13 33 L 15 30 L 17 28 L 19 26 L 20 25",
//             fill : "#000"
//         },
        
//     })

//     timeLine2.to(Xpath3.current , {
//         attr : {
            
//             d : "M 131.416 24.93 L 133.058 26.481 L 134.425 27.941 L 136.246 30.13 L 137.705 31.955 L 139.071 33.962 L 140.892 36.881 L 142.076 39.071 L 143.532 41.99 L 144.442 44.089 L 145.535 46.917 L 146.351 49.106 L 147.537 52.847 L 148.448 55.767 L 149.086 58.413 L 149.724 61.88 L 150.271 64.617 L 150.545 67.263 L 151 71 L 151 75 L 151 78 L 151 81 L 151 85 L 150.728 87.973 L 150.363 90.983 L 150 94 L 149.452 96.64 L 148.723 99.742 L 148 103 L 147 106 L 146 109 L 145 112 L 144 114 L 142.896 116.164 L 141.073 119.446 L 139.705 122.001 L 138.427 123.917 L 137 126 L 135.052 128.114 L 133.275 130.244 L 132 132 L 111.035 131.984 L 111.035 131.984 L 111.035 130.064 L 113 128 L 114.704 125.803 L 116.319 124.188 L 117.53 122.439 L 119.145 119.882 L 121 117 L 122.246 114.497 L 123.323 112.209 L 125 109 L 125.611 107.095 L 126.555 104.134 L 127.631 101.039 L 128.44 97.54 L 129.247 95.252 L 129.651 92.829 L 130 90 L 130.648 86.932 L 131 84 L 131 81 L 131 78 L 131 75 L 130.719 72.053 L 130.463 69.408 L 130 66 L 129.635 63.916 L 129 61 L 128.234 58.155 L 127.313 55.011 L 126.36 52.184 L 125.661 50.373 L 124.548 47.705 L 122.862 44.211 L 121.941 42.115 L 120.606 39.732 L 118.922 37.001 L 117.906 35.476 L 116 32.744 L 114.6 30.902 L 112.726 28.774 L 111.043 27.027 L 111.045 24.929 L 131.403 24.929",
//             fill : "#000",
//         },
//     })

//     const timeLine3 = gsap.timeline({
//         scrollTrigger : {
//             trigger : (windiwWidth > 990) ? design.current : designMobile.current,
//             // markers : true,
//             scroller : 'body',
//             start : 'top 70%',
//             end : "top 68%",
//             scrub: 2,
//         }
//     })
//     timeLine3.to(Xpath1.current , {
//         attr : {
//             d : "M7,14 L145,14 L146,14 L146,14 L147,14 L147,15 L148,15 L148,16 L149,16 L149,17 L149,17 L149,18 L149,42 L149,43 L149,43 L149,44 L148,44 L148,45 L147,45 L147,46 L146,46 L146,46 L145,46 L7,46 L6,46 L6,46 L5,46 L5,45 L4,45 L4,44 L3,44 L3,43 L3,43 L3,42 L3,18 L3,18 L3,17 L3,17 L3,16 L4,16 L4,15 L5,15 L5,14 L6,14 L6,14 L7,14",
//             fill : "#0031FF"
//         },
        
        

//     })

//     timeLine3.to(Xpath2.current , {
//         attr : {
//             d : "M7,63 L12,63 L16,63 L21,63 L25,63 L30,63 L34,63 L42,63 L47,63 L51,63 L59,63 L68,63 L76,63 L85,63 L94,63 L103,63 L111,63 L120,63 L128,63 L137,63 L145,63 L146,63 L146,63 L147,63 L147,64 L148,64 L148,65 L149,65 L149,66 L149,66 L149,67 L149,73 L149,79 L149,85 L149,91 L149,92 L149,92 L149,93 L148,93 L148,94 L147,94 L147,95 L146,95 L146,95 L145,95 L137,95 L128,95 L120,95 L111,95 L103,95 L94,95 L85,95 L76,95 L68,95 L59,95 L51,95 L42,95 L34,95 L25,95 L16,95 L7,95 L6,95 L6,95 L5,95 L5,94 L4,94 L4,93 L3,93 L3,92 L3,92 L3,91 L3,85 L3,79 L3,73 L3,67 L3,67 L3,66 L3,66 L3,65 L4,65 L4,64 L5,64 L5,63 L6,63 L6,63 L7,63",
//             fill : "#000"
//         },
        
//     })

//     timeLine3.to(Xpath3.current , {
//         attr : {
//             d : "M7,63 L12,63 L16,63 L21,63 L25,63 L30,63 L34,63 L42,63 L47,63 L51,63 L59,63 L68,63 L76,63 L85,63 L94,63 L103,63 L111,63 L120,63 L128,63 L137,63 L145,63 L146,63 L146,63 L147,63 L147,64 L148,64 L148,65 L149,65 L149,66 L149,66 L149,67 L149,73 L149,79 L149,85 L149,91 L149,92 L149,92 L149,93 L148,93 L148,94 L147,94 L147,95 L146,95 L146,95 L145,95 L137,95 L128,95 L120,95 L111,95 L103,95 L94,95 L85,95 L76,95 L68,95 L59,95 L51,95 L42,95 L34,95 L25,95 L16,95 L7,95 L6,95 L6,95 L5,95 L5,94 L4,94 L4,93 L3,93 L3,92 L3,92 L3,91 L3,85 L3,79 L3,73 L3,67 L3,67 L3,66 L3,66 L3,65 L4,65 L4,64 L5,64 L5,63 L6,63 L6,63 L7,63",
//             fill : "#000"
//         },
//     })

//     const masterTimeLine = gsap.timeline({
//         scrollTrigger : {
//             trigger : imageContainer.current,
//             scroller : 'body',
//             start : 'top 70%',
//             end : "top 68%",
//             scrub: 2,
//         }
//     }).add(timeLine2)

//     masterTimeLine.from(Xpath1.current , {
//         attr : {
//             d : "M158.624 0.293091C149.425 0.436219 141.209 0.644296 140.368 0.75535C138.548 0.995902 136.852 1.79573 135.792 2.91329C134.26 4.5296 91.738 60.2551 91.2903 61.2335C90.2458 63.5168 90.2089 65.8479 91.1868 67.7834C91.9739 69.3409 111.328 94.1937 112.259 94.8424C113.455 95.6753 114.676 96.0493 116.212 96.0531C118.159 96.058 119.616 95.46 120.915 94.1229C122.643 92.3442 183.805 12.8008 184.367 11.6008C185.054 10.136 185.202 7.76622 184.712 6.07233C183.632 2.33156 180.286 -0.107226 176.386 0.00362773C175.816 0.0198649 167.823 0.150164 158.624 0.293091Z",
//             fill : "#fff"
//         },
        
//     })

//     masterTimeLine.from(Xpath2.current , {
//         attr : {
//             d : "M26.4895 33.8704C5.00706 34.1218 6.76617 34.0456 5.18672 34.7932C1.72224 36.4325 -0.0067974 39.8277 2.00815e-05 44.9773C0.00342882 47.5752 0.294374 49.0839 1.12089 50.7908C1.38798 51.3421 12.258 65.3468 25.2766 81.9123C38.2954 98.4779 48.9471 112.076 48.9471 112.131C48.9471 112.186 38.361 126.416 25.4224 143.753C12.484 161.091 1.65386 175.637 1.3555 176.078C0.0274925 178.041 -0.370532 181.481 0.430121 184.073C1.29734 186.88 3.72016 189.387 6.3501 190.199C7.61194 190.589 8.36648 190.61 25.8485 190.737C42.2457 190.856 44.1579 190.837 45.2876 190.544C46.9747 190.106 48.0109 189.55 49.1849 188.452C49.7255 187.947 56.6302 179.154 64.5287 168.913L78.8895 150.293L79.3294 150.757C79.5714 151.012 86.3617 159.618 94.4187 169.882C102.476 180.145 109.359 188.788 109.715 189.087C110.652 189.875 112.119 190.571 113.559 190.91C115.474 191.362 149.366 191.357 151.102 190.905C152.766 190.472 154.116 189.689 155.464 188.375C158.134 185.772 158.887 181.838 157.355 178.492C156.851 177.392 49.6762 37.9686 47.9929 36.224C46.334 34.5047 43.7668 33.6086 40.7261 33.688C40.1195 33.7039 33.7131 33.786 26.4895 33.8704Z",
//             fill : "#fff"
//         },
       
//     })

//     masterTimeLine.from(Xpath3.current , {
//         opacity : 0,

//     })

// })















export default HomeBannerUpdated