import React from 'react'

function Graph() {
  return (
    <>
        <div className='graph'>
            <div className="circle blue">
                <p className='p1'>M&A Advisory,
                Financial Due Diligence
                Legal and Regulatory Compliance
                Integration Planning,
                Valuation Services</p>

                <p className='p2'>Strategic Advisory,
                Risk Management,
                Financial Modeling</p>
                        </div>
                        <div className="circle black">
                        <p className='p3'>Strategic Planning,
                Target Identification,
                Negotiation Support,
                Risk Management,
                Financial Modeling</p>
            </div>
        </div>
        <div className="graph-legends">
            <div className="legend first">
                <div className="legend-mark"></div>
                <h3>MQL (Marketing Qualified Leads)</h3>
            </div>
            <div className="legend second">
            <div className="legend-mark"></div>
            <h3>SQL (Sales Qualified Leads)</h3>
            </div>
        </div>
    </>
  )
}

export default Graph