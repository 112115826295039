import React from 'react'

function CustomerAcquisition({ data }) {

  return (
    <div className='customer-aqutsition' >
      <h2>{data.heading}</h2>
      <h3 dangerouslySetInnerHTML={{ __html: data.subHeading }} /> <br />
      <h4>{data.sussubHeading}</h4>
      <p className='smaller'>{data.para}</p>
      <p className='smaller'>{data.paras}</p>
      <br />
      <br />
      <h3>{data.subHeading2}</h3>
      <p className='smaller'>{data.para2}</p>
      <br /> <br /> <br />
      <div className='divpart'>
        <h3>{data.div_heading}</h3> <br />
        <h4>{data.subdivheading1}</h4>
        <p className='smaller'>{data.divpara1}</p> <br />
        <h4>{data.subdivheading2}</h4>
        <p className='smaller'>{data.divpara2}</p> <br />
        <h4>{data.subdivheading3}</h4>
        <p className='smaller'>{data.divpara3}</p> <br />
        <h4>{data.subdivheading4}</h4>
        <p className='smaller'>{data.divpara4}</p> <br />
        <h4>{data.subdivheading5}</h4>
        <p className='smaller'>{data.divpara5}</p> <br />
      </div>

    </div>
  )
}

export default CustomerAcquisition