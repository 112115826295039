import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import logo from '../assets/images/logo-prodmax.png';
import logo from '../assets/images/prodmax--01.svg';
import logoWhite from '../assets/images/prodmax-white-logo.svg';
import cross from '../assets/images/cross.webp';
import menu from '../assets/images/menu.webp';
import slogo from '../assets/images/prodmax-grey-01.svg'; // Import the new logo image
import caretDown from '../assets/images/caret-down.webp'
import '../styles/navbar.css';

function Navbar({
    activeImg,
    setActiveImg,
    setActiveImgX,
    handleImage,
}) {
    const [openMenu, setOpenMenu] = useState(false);
    const [submenuOpen, setSubmenuOpen] = useState(false); // State for submenu

    const [transparent, setTransparent] = useState(true)

    const scrollThreshold = 20; // Set the scroll threshold in pixels
    const location = useLocation();

    const width = window.innerWidth

    const [active, setActive] = useState(false);

    function handleToggleMenu() {
        setOpenMenu((prev) => !prev);
        setSubmenuOpen(false); // Close submenu when main menu toggles
    }

    function handleLogo() {
        setActive(true);
    }

    function closeMenu() {
        setOpenMenu(false);
        setSubmenuOpen(false); // Close submenu when main menu closes
    }

    function toggleSubmenu() {
        setSubmenuOpen((prev) => !prev);
    }

    useEffect(() => {
        if (location.pathname === '/') {
            const logoTimeout = setTimeout(() => {
                handleLogo();
            }, 1000);

            const imageTimeout = setTimeout(() => {
                handleImage();
            }, 2000);

            return () => {
                clearTimeout(logoTimeout);
                clearTimeout(imageTimeout);
            };
        }

        setActive(false);
        setActiveImg(false);
        setActiveImgX(false);
    }, [location.pathname]);

    const scrollContainerRef = useRef(null);

    const handleHomeClick = () => {
        if(location.pathname === '/'){
            window.location.reload()
        }
        
    };


    function handleScroll() {
        if(window.scrollY > 50){
            setTransparent(false)
        }else{
            setTransparent(true)
        }
    }


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <header id="menu" className={
            location.pathname === '/' && transparent 
            ? 
            "transparent" 
            : ((location.pathname === '/acquisition' && transparent) || (location.pathname === '/technology' && transparent) || (location.pathname === '/design' && transparent)) ?
            "transparent" :
            null}>
            <div className={`container-menu ${location.pathname === '/' && transparent 
            ? 
            "transparent" 
            : ((location.pathname === '/acquisition' && transparent) || (location.pathname === '/technology' && transparent) || (location.pathname === '/design' && transparent)) ?
            "transparent" :
            null}`}
               
            >
                {location.pathname === '/' ? (
                        <Link to="/"
                            ref={scrollContainerRef} 
                            className={`full-width-logo ${location.pathname === '/' && active ? 'active' : ''}`}
                            
                        >
                            <img src={ transparent ? logoWhite : logo} alt="Logo-prodmax" className={activeImg ? 'active' : ''} />
                        </Link>
                ) : null}

                <div className="open-menu" onClick={handleToggleMenu}>
                    <img src={menu} alt="open-menu" />
                </div>

                {location.pathname === '/' ? (
                    <div className="logo">
                        <Link to="/">
                            <img className={activeImg ? 'active' : ''} src={
                                location.pathname === '/' 
                                ? 
                                ((location.pathname === '/' && transparent)) ?
                                logoWhite
                                :
                                logo 
                                : ((location.pathname === '/acquisition' && transparent) || (location.pathname === '/technology' && transparent) || (location.pathname === '/design' && transparent)) ?
                                logoWhite
                                :
                                logo} alt="Logo-prodmax" />
                        </Link>
                    </div>
                ) : (
                    <Link to="/">
                        <img style={(width > 990) ? {height:'53px', width: "239px"} : {height:'53px', width: "200px"}} className={activeImg ? 'active' : ''} src={location.pathname === '/' 
                                ? 
                                logo 
                                : ((location.pathname === '/acquisition' && transparent) || (location.pathname === '/technology' && transparent) || (location.pathname === '/design' && transparent)) ?
                                logoWhite
                                :
                                logo} alt="Logo-prodmax" />
                    </Link>
                )}

                <nav className={openMenu ? 'active' : ''}>
                    <div className="close-menu" onClick={handleToggleMenu}>
                        <img src={cross} alt="open-menu" className="sizeimgcross" />
                    </div>
                    <ul>
                        <li>
                            <Link to="/" onClick={() => {
                                handleHomeClick()
                                closeMenu()
                            }}>Home</Link>
                        </li>
                        <li>
                            <Link to="/about" onClick={closeMenu}>About Us</Link>
                        </li>
                        <li>
                            <Link 
                            onClick={() => {
                                handleHomeClick()
                                toggleSubmenu()
                            }}
                                style={{display: 'flex', alignItems: 'baseline', gap : "10px"}}
                            >Services <img src={caretDown} className='down-arrow-submenu-icon' alt="caret-down" /> </Link>
                            <ul className={`submenu`}>
                                <li><Link to={"/acquisition"} onClick={closeMenu}>Acquisition</Link></li>
                                <li><Link to={"/technology"} onClick={closeMenu}>Technology</Link></li>
                                <li><Link to={"/design"} onClick={closeMenu}>Design</Link></li>
                            </ul>

                            <ul className={`mob-submenu ${submenuOpen ? 'open' : 'close'}`}>
                                <li><Link to={"/acquisition"} onClick={closeMenu}>Acquisition</Link></li>
                                <li><Link to={"/technology"} onClick={closeMenu}>Technology</Link></li>
                                <li><Link to={"/design"} onClick={closeMenu}>Design</Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/contact" onClick={closeMenu}>Contact Us</Link>
                        </li>
                    </ul>
                </nav>

                <span>
                    <Link to={'/contact'} className="contact-us1">Let's Talk</Link>
                </span>
            </div>
        </header>
    );
}

export default Navbar;
