import React, { useState } from 'react'
import dropDownArrow from '../assets/images/drop-down-arrow.webp'


function AccordianServicepage({mode, accordianList}) {

    const [activeIndex, setActiveIndex] = useState(null);

    function toggleAccordian(index){
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    }

  return (
    <div className='service-accordian-container'>

        {
            accordianList.map((list, i) => {
                return (
                    <div className={"accordian-info " + mode} key={list.id} onClick={() => toggleAccordian(i)}>
                        <div className="accordian-header">
                            <p style={{fontWeight : 600}}>{list.heading}</p>
                            <img src={dropDownArrow} 
                                className={(activeIndex === i) ? "active" : ""} 
                                alt="drop-down-arrow" 
                            />
                        </div>

                        <div className={(activeIndex === i) ? "accordian-body active" : "accordian-body"}>
                            <p className='smaller' dangerouslySetInnerHTML={{ __html: list.para }} />
                               
                        </div>
                    </div>
                )
            })
        }

        


    </div>
  )
}

export default AccordianServicepage