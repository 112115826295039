import React, { useEffect, useState } from 'react'

function ContactForm2() {

    //  Field values
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    
    const [companyName, setCompanyName] = useState('');
    const [msg, setMsg] = useState('');

    const [sendBtnActive, setSendButtonActive] = useState(false);
    const [warningActive, setWarningActive] = useState(false);
    
    // Field Validations Styles
    const [nameInvalid, setNameInvalid] = useState("gray");
    const [phoneInvalid, setPhoneInvalid] = useState("gray");
    const [emailInvalid, setEmailInvalid] = useState("gray");



  // Handle name change
  const handleNameChange = (e) => {
    const value = e.target.value;

    if(value.length === 0){
        setNameInvalid("red")
    }else{
        setNameInvalid("green")
    }


    if (/^[a-zA-Z\s]*$/.test(value)) {
      setName(value);
    }

  };

  // Handle phone number change
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if(value.length !== 10){
        setPhoneInvalid("red")
    }else{
        setPhoneInvalid("green")
    }

    if (/^\d*$/.test(value)) {
      setPhone(value);
    }
  };

  // Handle e-mail change
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)){
        setEmailInvalid("green")
    }else{
        setEmailInvalid("red")
    }

    
  };

  // Handle Company name change
  const handleCompanyNameChange = (e) => {
    const value = e.target.value;
    setCompanyName(value);
    
  };

  // Handle Message change
  const handleMsgChange = (e) => {
    const value = e.target.value;
    setMsg(value);
    
  };


//   Chnage Button Bg-color when Form is Validated
  useEffect(() => {
    if (name && phone.length === 10 && email) {
        if(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)){
            setSendButtonActive(true);
        }else{
            setSendButtonActive(false);
        }
    } else {
        setSendButtonActive(false);
    }

    setWarningActive(false)
  }, [name, phone, email]);

  

  const handleSubmit = (e) => {
    e.preventDefault();

    if(name === "" || phone === "" || email === ""){

        if(name === ""){
            setNameInvalid("red");
            setWarningActive(true)
        }else{
            setNameInvalid(false);
        }
        
        if(phone === ""){
            setPhoneInvalid("red");
            setWarningActive(true)
        }

        if(email === ""){
            setEmailInvalid("red");
            setWarningActive(true)
        }else{
            setEmailInvalid(false);
        }

    }else{

        if(sendBtnActive){
            // Add form submission logic here
            console.log(`
                        Name: ${name}, 
                        Phone: ${phone},
                        Email: ${email},
                        companyName: ${companyName},
                        msg: ${msg},
                        `, 
                    ); 
    
            setName('')
            setPhone('')
            setEmail('')
            setCompanyName('')
            setMsg('')
    
            setEmailInvalid(false);
            setPhoneInvalid(false);
            setNameInvalid(false);
        }else{
            setWarningActive(true)
        }


    }
    


  };








  return (
    <div className='contact-form desktop-view' >

        <h2>Send us a message</h2>

        <form onSubmit={handleSubmit}>

            <div className="form-section">

                <input type="text" name="name" id="name" placeholder='Name *'
                    value={name}
                    onChange={handleNameChange}

                    style={{
                        borderColor :
                        (nameInvalid === "red") ? 
                        'red' 
                        : (nameInvalid === "green") ?
                        'green'
                        :
                        '#C0C0C0'
                    }}

                />

                <input type="text" name="company-name" id="company-name" placeholder='Company Name'
                    value={companyName}
                    onChange={handleCompanyNameChange}
                />

            </div>

            <div className="form-section">

                <input type="text" name="phone-no" id="phone-no" placeholder='Phone *'
                    maxLength={10}
                    value={phone}
                    onChange={handlePhoneChange}

                    style={{
                        borderColor :
                        (phoneInvalid === "red") ? 
                        'red' 
                        : (phoneInvalid === "green") ?
                        'green'
                        :
                        '#C0C0C0'
                    }}
                />

                <input type="email" name="email" id="email" placeholder='Email *' 
                    value={email}
                    onChange={handleEmailChange}
                    style={{
                        borderColor :
                        (emailInvalid === "red") ? 
                        'red' 
                        : (emailInvalid === "green") ?
                        'green'
                        :
                        '#C0C0C0'
                    }}
                />

            </div>

            <div className="form-section">

                <textarea name=" message" id="message" placeholder='Message'
                    value={msg}
                    onChange={handleMsgChange}
                ></textarea>

            </div>
                    
            
            
            <button 
                type="submit"
                style={{
                    backgroundColor : sendBtnActive ? "#2FD549" : 'transparent'
                }}          
            >
                Send
            </button>

            <p
                style={{
                    backgroundColor : '#ffd5c9',
                    color : "red",
                    width : "100%",
                    textAlign : 'center',
                    padding : "3px",
                    marginTop : "4px",
                    opacity : warningActive ? 1 : 0
                }}
            >Please Fill Required Fields</p>

        </form>

    </div>
  )
}

export default ContactForm2