import React from 'react'
import AccordianServicepage from './AccordianServicepage'
import '../styles/detailInfo.css'


function GraphDetailedInfo({mode, dataObj}) {
  return (
    <div className={"container-3 " + mode}>
        <div className="container">
            <div className='graph-info'>
                <div className="mql">
                    
                    <h2>{dataObj.heading.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                {line}
                                <br />
                                </React.Fragment>
                        ))}
                    </h2>
                    <p className='smaller'>
                        {dataObj.para.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                {line}
                                <br />
                                </React.Fragment>
                        ))}
                    </p>
                </div>


                <AccordianServicepage mode={mode} accordianList={dataObj.accordianList} />


            </div>

        </div>
    </div>
  )
}

export default GraphDetailedInfo