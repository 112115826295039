import React, { useEffect, useRef, useState } from 'react'
import AccordianFaqs from './AccordianFaqs'

function FAQs() {


  const [active , setActive] = useState(false)

  const sectionRef = useRef(null);
  const sectionRef2 = useRef(null);

    useEffect(() => {
        const section = sectionRef.current;
        const section2 = sectionRef2.current;


        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                  handleChange();
                } 
                // else {
                //     section.classList.remove('animate');
                // }
            },
            {
                threshold: 0.3,
            }
        );

        if (section || section2) {
            observer.observe(section);
            observer.observe(section2);
        }

        return () => {
            if (section || section2) {
                observer.unobserve(section);
                observer.unobserve(section2);
            }
        };
    }, []);

  function handleChange(){
    setActive(true)
  }

  return (
    <section className='faqs' >
        <h2 className='desktop-faq-header' ref={sectionRef} onClick={handleChange}>Frequently <br /> Asked <br /> Questions</h2>
        <h2 className='mobile-faq-header' ref={sectionRef2} onClick={handleChange}>Frequently Asked Questions</h2>
        <AccordianFaqs active={active} />

    </section>
  )
}

export default FAQs