import React from 'react'
import '../styles/footer.css'
import { Link } from 'react-router-dom'
import linkedIn from "../assets/images/linked-in-icon.webp"

function Footer() {
    const cc={
        textAlign:'center'
    }
  return (
    <footer>
<div className="container2">
        <h2 style={cc} >Let us rise up together, <Link to={'/contact'} className='contact-us desktop-contact-btn'>contact us</Link> </h2>
        <h2 style={cc} className='mobile-contact-btn'><Link to={'/contact'} className='contact-us'>contact us</Link> </h2>

        <section className='desktop-footer'>
            <p className="copyright">Copyright@Prodmax 2024</p>
            <nav>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>

                    <li>
                        <Link to="/acquisition">Acquisition</Link>
                    </li>

                    <li>
                        <Link to="/technology">Technology</Link>
                    </li>                    

                    <li>
                        <Link to="/design">Design</Link>
                    </li>

                    <li>
                        <Link to="/about">About Us</Link>
                    </li>
                </ul>
            </nav>

            
            <Link className="social-media" to={'https://in.linkedin.com/'} target='_blank'>
                <img src={linkedIn} alt="linkedIn" className='linkdin' />
            </Link>

        </section>

        <section className='mobile-footer'>
            <nav>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>

                    <li>
                        <Link to="/acquisition">Acquisition</Link>
                    </li>

                    <li>
                        <Link to="/technology">Technology</Link>
                    </li>                    

                    <li>
                        <Link to="/design">Design</Link>
                    </li>

                    <li>
                        <Link to="/about">About Us</Link>
                    </li>
                </ul>
            </nav>

            <div className="col-2">
                
                <Link className="social-media" to={'https://in.linkedin.com/'} target='_blank'>
                    <img src={linkedIn} alt="linkedIn" className='linkdin' />
                </Link>


            </div>

        </section>
                <p className="copyright mobile">Copyright@Prodmax 2024</p>
        </div>
    </footer>
  )
}

export default Footer